import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import router from "@/router";
import store from '@/store'
let openMsg = false;
const service = axios.create({
    // 测试
    //   baseURL: 'https://purchase.chongda365.com/sz'+'/api/front/',
    //   baseURL: 'https://webmall.chongda365.com:70'+'/api/front/',
    // baseURL: 'http://47.98.208.6:21009' + '/api/front/',
    //   baseURL: 'http://shoping.zjhhkjwl.com:20011'+'/api/front/' ,
    // 正式
    baseURL: 'https://shoping.zjhhkjwl.com/crmebfront' + '/api/front/',
    // baseURL: 'http://192.168.30.103:20009'+ '/api/front/',
    timeout: 60000 // 过期时间
})

// request interceptor
service.interceptors.request.use(
    config => {
        // 发送请求之前做的
        const token = store.getters.token;
        if (token) {
            config.headers['Authori-zation'] = token
        }
        if (/get/i.test(config.method)) {
            config.params = config.params || {}
            config.params.temp = Date.parse(new Date()) / 1000
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
        if ([410000, 410001, 410002, 401].indexOf(res.code) !== -1) {
            store.commit('user/SET_TOKEN', '')
            store.commit('user/SET_UID', '')
            // to re-login
            if (!openMsg) {
                openMsg = true;
                MessageBox.confirm('您已经登出，您可以取消停留在这个页面，或重新登录', '退出', {
                    confirmButtonText: '或重新登录',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    router.push('/login')
                    openMsg = false;
                }).catch(() => {
                    openMsg = false;
                })
            }
        }
        // if the custom code is not 20000, it is judged as an error.
        return res
    },
    error => {
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service
