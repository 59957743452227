import request from '@/utils/request'

/**
 * 获取主页数据 无需授权
 */
export function getIndexData() {
  return request({
    url: 'index',
    method: 'get'
  })
}
/**
 * 获取搜索关键字获取
 *
 */
export function getSearchKeyword() {
  return request({
    url: 'search/keyword',
    method: 'get'
  })
}
/**
 * 获取搜索商品列表
 *
 */
export function getProducts(params) {
  return request({
    url: 'products',
    method: 'get',
    params
  })
}
/**
 * 获取分类
 *
 */
export function getCategory() {
  return request({
    url: 'category',
    method: 'get'
  })
}
/**
 * 获取小程序二维码
 */
export function getQrcode(params) {
  return request({
    url: 'qrcode/get',
    method: 'post',
    params:params
  })
}
/**
 * 退出登錄
 */
export function logout() {
  return request({
    url: 'logout',
    method: 'get'
  })
}
/**
 * 获取用户信息
 */
export function getUserInfo() {
  return request({
    url: 'user',
    method: 'get'
  })
}
/**
 * 获取购物车数量
 */
export function cartCount(numType,type) {
  return request({
    url: "cart/count?numType=" + numType + "&type=" + type,
    method: 'get'
  })
}

