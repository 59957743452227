<template>
  <div class="window-box" :style="{'backgroundColor': backgroundColor,'color':color}">
    <div class="window-width footer-menu">
      <div class="eqcode">
        <div>
          <el-image
              class="myimg"
              :src="imglist[0]"
              :preview-src-list="imglist">
          </el-image>
        </div>
        <div>
          小程序二维码
        </div>
      </div>
      <div class="footer-menu-line">
        <div>
          浙江合恒网络科技有限公司
        </div>
        <div class="line1"></div>
        <div>
          客服电话：0571-86297017
        </div>
        <div class="line1"></div>
        <div>
          公司地址：浙江省杭州市余杭区仓前街道舒心路359正元智慧大厦A幢1304室
        </div>
      </div>
      <div class="footer-menu-line">
        <div>
          版权所有 2023-现在
        </div>
        <div class="line1"></div>
<!--        <div>
          增值电信业务经营许可证：苏B2-20070195
        </div>
        <div class="line1"></div>-->
        <div>
          <a href="https://www.beian.gov.cn/" target="_blank">
            浙ICP备17043027号-4
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imglist:[require('@/assets/ercode.png')]
    }
  },
  props: {
    backgroundColor:{
      type: String,
      default: '#EC612A'
    },
    color:{
      type: String,
      default: '#ffffff'
    },
  },
  computed: {

  },
  methods: {

  }
}
</script>

<style scoped>
.window-box{
  /*background-color: #EC612A;*/
}
.footer-menu{
  height: 140px;
  position: relative;
}
.footer-menu-line{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  padding-top: 30px;
}
.footer-menu-line .line1{
  margin: 0 10px;
  border-left: 1px solid #ffffff;
  height: 14px;
}
.eqcode{
  position: absolute;
  right: 0;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.eqcode .myimg{
  width: 80px;
  height: 80px;
  margin-top: 20px;
}
</style>
