import Layout from '@/layout'

const orderRouter = {
  path: '/order',
  component: Layout,
  redirect: '/order/index',
  name: '订单中心',
  children: [
    {
      path: 'index',
      component: () => import('@/views/order/index'),
      name: 'order',
      meta: { title: '订单中心', icon: '' }
    }
  ]
}

export default orderRouter