import {getToken, setToken,getUid,setUid} from '@/utils/auth'
import {cartCount, getUserInfo} from '@/api/index'
// import Vue from "vue";
const state = {
    token: getToken(),
    userInfo: null,
    cartCount:0,
    userLoading:false,
    uid:getUid()
}
const mutations = {
    SET_TOKEN: (state, token) => {
        setToken(token)
        state.token = token
    },
    SET_UID: (state, uid) => {
        setUid(uid)
        state.uid = uid
    },
    UPDATE_USERINFO(state, userInfo) {
        state.userInfo = userInfo;
        // Vue.set(state,'userInfo',userInfo)
    },
    SET_CARTCOUNT(state,number){
        state.cartCount = number
    }
}

const actions = {
    USERINFO({state,commit
        }, force) {
        if(state.token){//没有token禁止更新
            state.userLoading = true
            return new Promise(reslove => {
                getUserInfo().then(res => {
                    if(res.code==200) {
                        commit("UPDATE_USERINFO", res.data);
                        reslove(res.data);
                        state.userLoading = false
                    }
                });
            }).catch(() => {
                state.userLoading = false
            });
        }else{
            commit("UPDATE_USERINFO", {});
        }
    },
    CARTCOUNT({state,commit
              }, force){
        if(state.token){//没有token禁止更新
            return new Promise(reslove => {
                cartCount(true,'total').then(res => {
                    if(res.code==200){
                        commit("SET_CARTCOUNT", res.data.count);
                        reslove(res.data);
                    }
                });
            }).catch(() => {

            });
        }else{
            commit("SET_CARTCOUNT", 0);
        }
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
