import Layout from '@/layout'

const goodsRouter = {
  path: '/goods',
  component: Layout,
  redirect: '/goods/index',
  name: '商品',
  children: [
    {
      path: 'index',
      component: () => import('@/views/goods/index'),
      name: 'UserIndex',
      meta: { title: '商品详情', icon: '' }
    },
    {
      path: 'list',
      component: () => import('@/views/goods/list'),
      name: 'UserList',
      meta: { title: '商品列表', icon: '' }
    }
  ]
}

export default goodsRouter
