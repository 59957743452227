import Layout from '@/layout'

const paymentRouter = {
  path: '/payment',
  component: Layout,
  redirect: '/payment/index',
  name: '支付下单',
  children: [
    {
      path: 'index',
      component: () => import('@/views/payment/index'),
      name: 'Payment',
      meta: { title: '支付下单', icon: '' }
    }
  ]
}

export default paymentRouter