import Cookies from 'js-cookie'

const TokenKey = 'Authori-zation'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getUid() {
  return Cookies.get('UID')
}

export function setUid(UID) {
  return Cookies.set('UID', UID)
}

export function removeUid() {
  return Cookies.remove('UID')
}
