import Layout from '@/layout'

const orderDetailRouter = {
  path: '/orderDetail',
  component: Layout,
  redirect: '/orderDetail/index',
  name: '订单详情',
  children: [
    {
      path: 'index',
      component: () => import('@/views/orderDetail/index'),
      name: 'orderDetail',
      meta: { title: '订单详情', icon: '' }
    }
  ]
}

export default orderDetailRouter