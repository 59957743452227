<template>
  <div class="app-wrapper">
    <Header/>
    <router-view :key="key" class="content-h"/>
    <Footer/>
  </div>
</template>

<script>

import Header from './components/header'
import Footer from './components/footer'
export default {
  name: 'Layout',
  data(){
    return {

    }
  },
  components: {
    Header,
    Footer
  },
  computed: {
    key () {
      return this.$route.path
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
  .content-h{
    min-height: calc(100vh - 180px);
    padding: 10px;
  }
</style>
