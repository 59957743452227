<template>
  <div class="window-box">
    <div class="window-width header-menu">
      <div class="header-menu-left">
        <div v-if="userLoading" class="login">
          加载中....
        </div>
        <div class="user" v-else-if="userInfo.nickname" @click.stop="clickUser">
          {{userInfo.nickname}}<i class="el-icon-caret-top" v-if="showUser"></i><i class="el-icon-caret-bottom" v-else></i>
        </div>
        <div class="login" v-else @click="login">
          未登录
        </div>
      </div>
      <div class="header-menu-right">
        <div class="myindex" @click="toIndex">
          商城首页
        </div>
        <div class="cart" @click="toShopping">
          <img src="@/assets/icon/cart.png"/>购物车<template v-if="cartCount">({{cartCount}})</template>
        </div>
        <div class="center" @click.stop="clickCenter">
          个人中心
        </div>
        <div class="kefu" @click.stop="clickKefu">
          客服
        </div>
      </div>
      <div class="user-info" :class="{'active':showUser}" @click.stop="stop">
        <div class="refresh" @click="refresh">
          <img src="@/assets/icon/refresh.png"/>
        </div>
        <div class="user-info-line">
          <div class="user-info-line-item">
            <img src="@/assets/icon/user.png"/>
            <label class="user-info-line-top line1">{{userInfo.nickname}}</label>
            <label class="user-info-line-name">工号:</label>
            <label class="user-info-line-value line1">{{userInfo.jobNum}}</label>
          </div>
          <div class="user-info-line-item">
            <img src="@/assets/icon/money.png"/>
            <label class="user-info-line-name">账号余额:</label>
            <label class="user-info-line-value">{{userInfo.nowMoney}}</label>
          </div>
        </div>
        <div class="user-info-line">
          <div class="user-info-line-item">
            <img src="@/assets/icon/phone.png"/>
            <label class="user-info-line-name">手机号:</label>
            <label class="user-info-line-value">{{userInfo.phone&&(userInfo.phone.substring(0,3)+'****'+userInfo.phone.substring(7))}}</label>
          </div>
          <div class="user-info-line-item">
            <img src="@/assets/icon/address.png"/>
            <label class="user-info-line-name">部门:</label>
            <label class="user-info-line-value">{{userInfo.deptName}}</label>
          </div>
        </div>
        <div class="user-info-line">
<!--          <div class="user-info-line-item user-info-desc">
            <div @click.stop="clickKefu">
              请登录小程序查询订单以及订单售后
            </div>
          </div>-->
          <div class="user-info-line-item logout" @click="logout">
            <img src="@/assets/icon/logout.png"/>
            <label class="user-info-line-name">退出登录</label>
          </div>
        </div>
      </div>
      <div class="kefu-info" :class="{'active':showKefu}" @click.stop="stop">
        <el-image
            class="myimg"
            :src="imglist[0]"
            :preview-src-list="imglist">
        </el-image>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";
import {logout} from '@/api/index'
export default {
  data() {
    return {
      showUser:false,
      showKefu:false,
      imglist:[require('@/assets/ercode.png')]
    }
  },
  computed: mapGetters(["userInfo","cartCount","userLoading","uid"]),

  mounted() {
    this.$store.dispatch("user/USERINFO");
    this.$store.dispatch("user/CARTCOUNT");
    (function (w, d, n, a, j) {
      w[n] = w[n] || function () {
        (w[n].a = w[n].a || []).push(arguments);
      };
      j = d.createElement('script');
      j.async = true;
      j.src ='https://qiyukf.com/script/5525faecd0d1a0160f03ed0486cd7c94.js';
      d.body.appendChild(j);
    })(window, document, 'ysf');
  },
  destroyed() {
    let doc = document.getElementById('YSF-BTN-HOLDER')
    doc&&doc.remove()
  },
  methods: {
    stop(){},
    login(){
      router.push('/login')
    },
    toIndex(){
      router.push('/index')
    },
    toShopping(){
      router.push('/shoppingCart/index')
    },
    logout(){
      logout().then(res=>{
        this.$store.commit('user/SET_TOKEN', '')
        router.push('/login')
      })
    },
    clickUser(){
      this.showUser = !this.showUser;
      if(this.showUser){
        document.addEventListener('click',this.clickUser)
      }else{
        document.removeEventListener('click',this.clickUser)
      }
    },
    clickCenter() {
      router.push('/center/index')
    },
    clickKefu(){
      let doc = document.getElementById('YSF-BTN-HOLDER')
      if(doc){
        if(this.uid&&this.userInfo.phone){
          window.ysf('config',{
            uid:this.uid,
            mobile:this.userInfo.phone
          })
        }
        doc.click()
      }else{
        this.showKefu = !this.showKefu;
        if(this.showKefu){
          document.addEventListener('click',this.clickKefu)
        }else{
          document.removeEventListener('click',this.clickKefu)
        }
      }
    },
    refresh(){
      this.$store.dispatch("user/USERINFO");
    }
  }
}
</script>

<style scoped>
.window-box{
  background-color: #fff;
}
.header-menu{
  display: flex;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;
  position: relative;
}
.user{
  font-size: 14px;
  color: #333333;
  cursor:pointer;
}
.login{
  font-size: 14px;
  color: #999999;
  cursor:pointer;
}
.header-menu-right{
  display: flex;
  color: #333333;
  font-size: 12px;
}
.header-menu-right .myindex{
  cursor:pointer;
}
.header-menu-right .cart{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  cursor:pointer;
}
.header-menu-right .center {
  margin: 0 20px 0 0;
  cursor:pointer;
}

.header-menu-right .cart img{
  width: 16px;
  height: 14px;
  margin-right: 2px;
}
.header-menu-right .kefu{
  cursor:pointer;
}
.user-info{
  position: absolute;
  top: 42px;
  background: #fff;
  padding: 10px 0;
  border-radius: 5px;
  box-shadow: 2px 4px 4px 0px rgba(0,0,0,0.2);
  opacity: 0;
  z-index: -1;
}
.user-info.active{
  opacity: 1;
  z-index: 11;
  transition: opacity 0.3s;
}
.user-info-line{
  display: flex;
  width: 400px;
  position: relative;
}
.user-info-line + .user-info-line{
  margin-top: 10px;
}
.user-info-line-item{
  display: flex;
  /*align-items: center;*/
  margin-left: 10px;
  line-height: 20px;
  flex: 1;
  width: 190px;
}
.user-info-line-item img{
  width: 20px;
  height: 20px;
}
.user-info-line-top{
  font-size: 16px;
  color: #333333;
  margin: 0 5px 0 5px;
  font-weight: bold;
  max-width: 50px;
}
.user-info-line-name{
  font-size: 14px;
  color: #666666;
  margin-left: 5px;
  white-space: nowrap;
}
.user-info-line-value{
  font-size: 14px;
  color: #333333;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.logout{
  justify-content: flex-end;
  margin-right: 10px;
  cursor:pointer;
}
.logout label{
  cursor:pointer;
}
.refresh{
  position: absolute;
  right: 10px;
  top: 0;
  cursor:pointer;
  z-index: 9;
}
.refresh img{
  width: 14px;
  height: 14px;
}
.kefu-info{
  position: absolute;
  top: 42px;
  right: 0;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 2px 4px 4px rgba(0,0,0,0.2);
  opacity: 0;
  z-index: -1;
}
.kefu-info.active{
  opacity: 1;
  z-index: 11;
  transition: opacity 0.3s;
}
.kefu-info .myimg{
  width: 300px;
  height: 300px;
}
.user-info-desc{
  color: #9999ff;
  font-size: 12px;
  line-height: 20px;
  cursor:pointer;
  flex: 2;
}
</style>
