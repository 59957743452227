import Layout from '@/layout'

const cartRouter = {
  path: '/shoppingCart',
  component: Layout,
  redirect: '/shopping-cart/index',
  name: '购物车',
  children: [
    {
      path: 'index',
      component: () => import('@/views/shopping-cart/index'),
      name: 'shoppingCart',
      meta: { title: '购物车', icon: '' }
    }
  ]
}

export default cartRouter
