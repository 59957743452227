import Layout from '@/layout'

const centerRouter = {
  path: '/center',
  component: Layout,
  redirect: '/center/index',
  name: '个人中心',
  children: [
    {
      path: 'index',
      component: () => import('@/views/center/index'),
      name: 'center',
      meta: { title: '个人中心', icon: '' }
    }
  ]
}

export default centerRouter